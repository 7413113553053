import React from "react"
import { APIpath, fetchBannerBlog, fetchActiveBlogs } from '../actions'
import { getPost, getPosts, getBanners } from '../reducers'
import { connect } from 'react-redux'
import { Image, Text, Flex, Box, Button, Heading, Tag } from '@chakra-ui/react'
import Layout from '../layout'
import call from '../multimedia/imagenes/call2.jpg'
import HeroCarousel from "../components/HeroCarousel"
import { Link } from "react-router-dom"
import { isEmpty  } from "../utils"

class BlogPost extends React.Component {


    componentDidMount() {
        this.props.fetchActiveBlogs()
        this.props.fetchBannerBlog()
    }

    render() {

        const { activePost, posts, banners } = this.props

        let i = 0

        if ( isEmpty(activePost)) {
            return (<Layout > Cargando... </Layout>)
        } else {

            let activeTags = activePost.tag !== '' ? activePost.tag.split(",") : []

            let fecha = new Date(activePost.updated_at)

            return (
                <Layout >
                    <Flex flexDir={{ base: 'column', md: 'row' }} bg={"easycar1.500"} w={"100%"} p={5} textAlign={"center"} marginTop={"20px"} marginBottom={"20px"} alignItems={"center"} display={"flex"} justifyContent={"space-evenly"}>
                        <Text as={"b"} fontSize={"4xl"} color={"white"}>Llévate tu próximo vehículo en pagos mensuales en 24 horas</Text> <a
                            href={`https://wa.me/+50498493492?text=Hola,%20estoy%20interesado%20en%20comprar%20un%20vehículo.`}
                            target={"_blank"}
                            rel="noreferrer">
                            <Button >
                                Chatea con un asesor
                            </Button>
                        </a>
                    </Flex>

                    <Box w={"full"} >

                        <Box maxW={"1400px"} flexDir={'column'} p={4} m={"auto"} mt={"7.5rem"}>
                            <Heading > {activePost.title} </Heading>
                        </Box>
                        <Image alt={activePost.title} height={"700px"} width={"full"} src={`${APIpath}/blog/image/${activePost.id}`} objectFit='cover' />

                        <Flex maxW={"1400px"} p={4} m={"auto"} gap={3}>
                            <Text>Publicado: {fecha.toLocaleDateString()} </Text> {activeTags.map(tagText => <Tag key={tagText} colorScheme={"easycar1"}>{tagText}</Tag>)}
                        </Flex>


                        <Flex flexDir={{ base: 'column', md: 'row' }} maxW={"1400px"} p={4} m={"auto"} mt={"1rem"} gap={3}>

                            <Box width={{ base: 'full', md: "70%" }} p={6} bg={"gray.50"}>
                                <div className="blogContent" dangerouslySetInnerHTML={{ __html: activePost.content }} />
                            </Box>

                            <Box width={{ base: 'full', md: "30%" }} >
                                {posts.filter(postSimilar => {
                                    if (postSimilar.id === activePost.id) return false
                                    else {
                                        if (activeTags.length > 0) {
                                            return activeTags.filter(tag => {
                                                if (postSimilar.tag !== '') {
                                                    let tags = postSimilar.tag.split(",")
                                                    return tags.filter(tagSimilar => tag === tagSimilar).length > 0
                                                }
                                                return false
                                            }).length > 0
                                        } else {
                                            return true
                                        }
                                    }
                                }).map(post => {
                                    if (i < 4) {
                                        i++
                                        return <Box width={{ base: 'full', md: "100%" }} bg={"gray.50"} key={post.id} >
                                            <Link to={`/blog/${post.id}?${Math.random().toString(36)}`}>
                                                <Image alt={post.title} height={"250px"} width={"full"} src={`${APIpath}/blog/image/${post.id}`} objectFit='cover' />
                                                <Box height={"150px"} p={6}>
                                                    <Text noOfLines={1} fontSize={"xl"} as={"b"}>{post.title}</Text>
                                                    <Text noOfLines={3}> {post.description}</Text>
                                                </Box>
                                            </Link>
                                        </Box>
                                    }
                                    return undefined
                                }
                                )}
                            </Box>


                        </Flex>
                    </Box>

                    <HeroCarousel banners={banners} />

                    <Flex flexDir={{ base: 'column', md: 'row' }} bg={"easycar1.500"} w={"100%"} p={5} textAlign={"center"} marginTop={"20px"} marginBottom={"20px"} alignItems={"center"} display={"flex"} justifyContent={"space-evenly"}>
                        <Text as={"b"} fontSize={"4xl"} color={"white"}>Llévate tu próximo vehículo en pagos mensuales en 24 horas</Text> <a
                            href={`https://wa.me/+50498493492?text=Hola,%20estoy%20interesado%20en%20comprar%20un%20vehículo.`}
                            target={"_blank"}
                            rel="noreferrer">
                            <Button >
                                Chatea con un asesor
                            </Button>
                        </a>
                    </Flex>

                    <Box w={"full"} mt={"7.5rem"}>
                        <Flex maxW={"1400px"} flexDir={{ base: 'column', md: 'row' }} p={4} m={"auto"}>
                            <Box flex={1} alignItems={"center"} justifyContent={"center"} margin={"auto"}>
                                <Image alt={'Call Center'} height={"auto"} width={{ base: '100%', md: "100%" }} src={call} />
                            </Box>
                            <Box flex={1} p={"10"} margin={"auto"} >
                                <Heading>Aprobamos tu plan de pago con la mejor asesoría</Heading>
                                <br />
                                <Text>Contamos con asesores personalizados que te acompañaran en todo el proceso</Text>
                                <br />
                                <Link to={"/requisitos"} onClick={() => window.scrollTo(0, 0)}>
                                    <Button colorScheme="easycar2">Compra tu vehículo en cuotas</Button>
                                </Link>
                            </Box>
                        </Flex>
                    </Box>
                </Layout>
            )

        }
    }

}

const mapStateToProps = (state, ownProps) => ({
    activePost: getPost(state, ownProps.match.params.id),
    posts: getPosts(state),
    banners: getBanners(state)
})

const mapDispatchToProps = { fetchBannerBlog, fetchActiveBlogs }

export default connect(mapStateToProps, mapDispatchToProps)(BlogPost)
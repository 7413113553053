import React, { useEffect } from "react"
import HeroCarousel from "../components/HeroCarousel"
import CarouselMulti from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import SideCar from "../components/SideCar"
import ModalApp from "../containers/Modal"
import { Flex, Box, Heading, Text, Button, Image, Card, CardBody, Icon } from '@chakra-ui/react'
import Layout from '../layout'
import card2 from '../multimedia/slides-cards/1.jpg'
import card3 from '../multimedia/slides-cards/2.jpg'
import card4 from '../multimedia/slides-cards/3.jpg'
import card5 from '../multimedia/slides-cards/4.jpg'
import card6 from '../multimedia/slides-cards/5.jpg'
import agencia1 from '../multimedia/imagenes/palmira.jpg'
import agencia2 from '../multimedia/imagenes/choluteca.png'
import agencia3 from '../multimedia/imagenes/comayagua.jpg'
import agencia4 from '../multimedia/imagenes/sanpedrosula.jpg'
import vehiculo from '../multimedia/imagenes/piensaEasy.png'
import call from '../multimedia/imagenes/call2.jpg'
import { Link } from "react-router-dom"
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { fetchActiveBanners } from "../actions"
import { useSelector, useDispatch } from 'react-redux'
import { getBanners } from "../reducers"

const responsive = {
    largeDesktop: {
        breakpoint: { max: 3000, min: 1400 },
        items: 4,
        partialVisibilityGutter: 30
    },
    desktop: {
        breakpoint: { max: 1400, min: 1024 },
        items: 3,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        partialVisibilityGutter: 30
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 30
    }
};

const CustomRightArrow = ({ onClick, ...rest }) => {
    return <Button onClick={() => onClick()} style={{
        position: "absolute", outline: 0, transition: "all .5s", zIndex: 1000, opacity: 1, cursor: "pointer", border: 0, background: "rgba(0,0,0,0.7)", right: "0", height: "100%"
    }}>
        <Icon as={ChevronRightIcon} color={"white"} boxSize={8} />
    </Button>
}

const CustomLeftArrow = ({ onClick, ...rest }) => {
    return <Button onClick={() => onClick()} style={{
        position: "absolute", outline: 0, transition: "all .5s", zIndex: 1000, opacity: 1, cursor: "pointer", border: 0, background: "rgba(0,0,0,0.7)", left: "0", height: "100%"
    }}>
        <Icon as={ChevronLeftIcon} color={"white"} boxSize={8} />
    </Button>
}

const Landing = () => {

    const dispatch = useDispatch()

    const banners = useSelector(state => getBanners(state))

    useEffect(() => {
        dispatch(fetchActiveBanners())
    }, [])


    return (
        <Layout>
             <HeroCarousel banners={banners} />

            <Box maxW={"1400px"} margin={"auto"} mb={"30px"} mt={"7.5rem"} p={5}>
                <Heading textAlign={"center"}>¿Por qué comprar un vehículo con nosotros?</Heading>
                <br /><br />
                <CarouselMulti showDots={false} infinite={false} responsive={responsive} partialVisible={true} customRightArrow={<CustomRightArrow />} customLeftArrow={<CustomLeftArrow />} >

                    <SideCar
                        src={card2}
                        title={" Garantia en vehiculos EASYCAR"}
                        content={"Te ofrecemos garantía hasta 90 días"}
                    />

                    <SideCar
                        src={card3}
                        title={"Contamos con variedad de vehículos"}
                        content={"Conoce nuestras diferentes marcas y modelos de vehículos para que elijas lo que siempre soñaste."}
                    />
                    <SideCar
                        src={card4}
                        title={"Pensamos en tu seguridad"}
                        content={"Los vehículos importados por EASYCAR cuenta con sistema de seguridad pasiva"}
                    />

                    <SideCar
                        src={card5}
                        title={" Si lo sueñas, te imaginas conducirlo?"}
                        content={"Realiza tu prueba de manejo y vive la experiencia EASYCAR."}
                    />
                    <SideCar
                        src={card6}
                        title={"Nuestros vehículos como nuevos"}
                        content={"Acondicionamos y preparamos nuestros vehículos en una red de talleres especializados."}
                    />
                </CarouselMulti>
                <br />
                <Box textAlign={"center"}>
                    <Link to={"/cars"} onClick={() => window.scrollTo(0, 0)}>
                        <Button colorScheme={"easycar1"} >Compra tu vehículo</Button>
                    </Link>
                </Box>
            </Box>

            <Box w={"full"} mt={"7.5rem"}>
                <Flex flexDir={{ base: 'column', lg: 'row' }} minHeight={{ base: "initial", lg: "500px" }}>
                    <Box maxWidth={{ base: '100%', lg: "50%" }} bg={"easycar1.500"} flex={1} p={"16"} color={"white"} alignContent={"center"} alignItems={"center"}>
                        <Box maxWidth={"600px"} margin={"auto"}>
                            <Heading>Piensa EASY</Heading>
                            <br />
                            <Text fontSize={{ base: "initial", lg: "2xl" }} textAlign={"justify"}>
                                Comprar un seminuevo en EasyCar es más que una compra, es una decisión
                                inteligente. Contribuyes al medio ambiente, apoyas la economía circular y, además,
                                vives una experiencia premium en la entrega de tu auto. ¡Nos encargamos de todo
                                para que tu única preocupación sea salir a disfrutarlo!
                            </Text>
                        </Box>
                    </Box>
                    <Box maxWidth={{ base: '100%', lg: "50%" }} flex={1} display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"auto"}>
                        <Image alt={'Vehiculos easycar'} height={"auto"} maxWidth={"700px"} src={vehiculo} />
                    </Box>
                </Flex>
            </Box>

            <Box w={"full"} mt={"7.5rem"}>
                <Flex maxW={"1400px"} flexDir={{ base: 'column', md: 'row' }} p={4} m={"auto"}>
                    <Box flex={1} alignItems={"center"} justifyContent={"center"} margin={"auto"}>
                        <Image alt={'Call Center'} height={"auto"} width={{ base: '100%', md: "100%" }} src={call} />
                    </Box>
                    <Box flex={1} p={"10"} margin={"auto"} >
                    <Text fontSize={{ base: "initial", lg: "2xl" }}>En EasyCar, nuestro sueño es  <b className={"colorEasycar"}>llevarte a tu destino.</b></Text>
                        <br />
                        <Text fontSize={{ base: "initial", lg: "2xl" }}> Con nuestra asesoria personalizada te ayudamos a encontrar tu
                            nuevo compañero de aventura.</Text>
                        <br />
                        <Link to={"/requisitos"} onClick={() => window.scrollTo(0, 0)} >
                            <Button colorScheme="easycar2">Compra tu vehículo en cuotas</Button>
                        </Link>
                    </Box>
                </Flex>
            </Box>

            <Box bg={'gray.100'} w={"full"} p={5} mt={"7.5rem"}>
                <Heading textAlign={"center"}>¡Visita nuestras sedes y conócenos!</Heading>
                <LoadScript googleMapsApiKey="AIzaSyCggvraT4pGMXL0J4wLlfp_b5yoViJ95EY" >
                    <Flex flexDir={{ base: 'column', lg: 'row' }} flexWrap={"wrap"} justifyContent={"space-around"} alignItems={"center"} p={"6"} mt={"10px"} gap={"10px"}>

                        <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                            <Box maxH={"350px"} overflow={"hidden"}>
                                <Image src={agencia1} w={"100%"} objectFit={'cover'} />
                            </Box>
                            <CardBody>
                                <Text as={"b"}>Agencia Tegucigalpa</Text>
                                <Text>Colonia el trapiche, atrás de torre artemisa frente a Futeca</Text>
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>+504 9484-1970</Text>
                                <ModalApp title={"Agencia Tegucigalpa"} >

                                    <GoogleMap
                                        zoom={16}
                                        mapContainerStyle={{ width: '100%', height: '500px' }}
                                        center={{ lat: 14.101008502992348, lng: -87.19782151893402 }}
                                    >
                                        <Marker position={{ lat: 14.101008502992348, lng: -87.19782151893402 }} ></Marker>
                                    </GoogleMap>
                                    <br />
                                    <Text as={"b"}>Ubicación:</Text>
                                    <Text>Colonia el trapiche, atrás de torre artemisa frente a Futeca</Text>
                                    <br />
                                    <Text as={"b"}>Horario:</Text>
                                    <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                    <Text>Sábado de 8:00 am a 12:00 md</Text>
                                    <br />
                                    <Text as={"b"}>Télefonos:</Text>
                                    <Text>+504 9484-1970</Text>
                                </ModalApp>
                            </CardBody>
                        </Card>

                        <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                            <Box maxH={"350px"} overflow={"hidden"}>
                                <Image src={agencia2} h={"350px"} objectFit={'cover'} />
                            </Box>
                            <CardBody>
                                <Text as={"b"}>Agencia Choluteca</Text>
                                <Text>Barrio el hospital, plaza futura, contiguo a farmacia Kielsa</Text>
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>+504 9581-5675</Text>
                                <ModalApp title={"Agencia Choluteca"} >

                                    <GoogleMap
                                        zoom={16}
                                        mapContainerStyle={{ width: '100%', height: '500px' }}
                                        center={{ lat: 13.302200825907976, lng: -87.19820827393193 }}
                                    >
                                        <Marker position={{ lat: 13.302200825907976, lng: -87.19820827393193 }} ></Marker>
                                    </GoogleMap>
                                    <br />
                                    <Text as={"b"}>Ubicación:</Text>
                                    <Text>Barrio el hospital, plaza futura, contiguo a farmacia Kielsa</Text>
                                    <br />
                                    <Text as={"b"}>Horario:</Text>
                                    <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                    <Text>Sábado de 8:00 am a 12:00 md</Text>
                                    <br />
                                    <Text as={"b"}>Télefonos:</Text>
                                    <Text>+504 9581-5675</Text>
                                </ModalApp>
                            </CardBody>
                        </Card>


                        <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                            <Box maxH={"350px"} overflow={"hidden"}>
                                <Image src={agencia3} h={"350px"} w={"100%"} objectFit={'cover'} />
                            </Box>
                            <CardBody>
                                <Text as={"b"}>Agencia Comayagua</Text>
                                <Text>Barrio Torondon, plaza roble, Comayagua.</Text>
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>+504 9849-2795</Text>
                                <ModalApp title={"Agencia Comayagua"} >

                                    <GoogleMap
                                        zoom={16}
                                        mapContainerStyle={{ width: '100%', height: '500px' }}
                                        center={{ lat: 14.4511706, lng: -87.6425045 }}
                                    >
                                        <Marker position={{ lat: 14.4511706, lng: -87.6425045 }} ></Marker>
                                    </GoogleMap>
                                    <br />
                                    <Text as={"b"}>Ubicación:</Text>
                                    <Text>Barrio Torondon, plaza roble, Comayagua.</Text>
                                    <br />
                                    <Text as={"b"}>Horario:</Text>
                                    <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                    <Text>Sábado de 8:00 am a 12:00 md</Text>
                                    <br />
                                    <Text as={"b"}>Télefonos:</Text>
                                    <Text>+504 9849-2795</Text>
                                </ModalApp>
                            </CardBody>
                        </Card>

                        <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                            <Box maxH={"350px"} overflow={"hidden"}>
                                <Image src={agencia4} w={"100%"} objectFit={'cover'} />
                            </Box>
                            <CardBody>
                                <Text as={"b"}>Agencia San Pedro Sula</Text>
                                <Text>Blv del Sur, frente a estación de bomberos al lado de colegio de abogados</Text>
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>+504 9464-9447</Text>
                                <ModalApp title={"Agencia San Pedro Sula"} >


                                    <br />
                                    <Text as={"b"}>Ubicación:</Text>
                                    <Text>Blv del Sur, frente a estación de bomberos al lado de colegio de abogados</Text>
                                    <br />
                                    <Text as={"b"}>Horario:</Text>
                                    <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                    <Text>Sábado de 8:00 am a 12:00 md</Text>
                                    <br />
                                    <Text as={"b"}>Télefonos:</Text>
                                    <Text>+504 9464-9447</Text>
                                </ModalApp>
                            </CardBody>
                        </Card>

                    </Flex>
                </LoadScript>
                <Box textAlign={"center"}>
                    <Link to={"/agencias"} onClick={() => window.scrollTo(0, 0)}>
                        <Button colorScheme="easycar1">Conoce nuestras sedes</Button>
                    </Link>
                </Box>
            </Box>

            <Box textAlign={"center"} w={"full"} mt={"7.5rem"} mb={"2rem"}>
                <Heading>Lo que opinan nuestros clientes</Heading>
            </Box>
            <Box display={"flex"} flexDir={"column"} alignItems={"center"} justifyContent={"center"} w={"full"} textAlign={"center"} mb={"2rem"} >
                <Flex w={"full"} flexDir={{ base: 'column', lg: 'row' }} justifyContent={"space-around"} alignItems={"center"} gap={"10px"}>
                    <iframe
                        className={"video"}
                        src="https://www.youtube.com/embed/C43o-mQwiNM"
                        title="Experiencia EASYCAR 1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                        allowFullScreen></iframe>
                    <iframe
                        className={"video"}
                        src="https://www.youtube.com/embed/x88-Zx_nT9c"
                        title="Experiencia EASYCAR 2"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                        allowFullScreen></iframe>
                </Flex>
                <Link to={"/experiencia-easycar"} onClick={() => window.scrollTo(0, 0)}>
                    <Button colorScheme="easycar1" mt={"1rem"}>Ver más historias de clientes</Button>
                </Link>
            </Box>
        </Layout>
    )
}

export default Landing
import React from "react"
import { fetchActiveBlogs, APIpath } from '../actions'
import { getPosts } from '../reducers'
import { connect } from 'react-redux'
import { Image, Text, Flex, Box, Button, Heading, SimpleGrid } from '@chakra-ui/react'
import Layout from '../layout'
import vehiculo from '../multimedia/imagenes/vehiculo3.png'
import { Link } from "react-router-dom"


class BlogLanding extends React.Component {

    componentDidMount() {
        this.props.fetchActiveBlogs()
    }


    render() {

        const { posts } = this.props


        return (
            <Layout>
                <Flex flexDir={{ base: 'column', md: 'row' }} bg={"easycar1.500"} w={"100%"} p={5} textAlign={"center"} marginTop={"20px"} marginBottom={"20px"} alignItems={"center"} display={"flex"} justifyContent={"space-evenly"}>
                    <Text as={"b"} fontSize={"4xl"} color={"white"}>Llévate tu próximo vehículo en pagos mensuales en 24 horas</Text> <a
                        href={`https://wa.me/+50372900087?text=Hola%2C+me+gustaria+precalificar+para+adquirir+un+veh%C3%ADculo+de+su+sitio+web+en+pagos+mensuales.&type=phone_number&app_absent=0`}
                        target={"_blank"}
                        rel="noreferrer">
                        <Button >
                            Chatea con un asesor
                        </Button>
                    </a>
                </Flex>


                <Box w={"full"} >
                    <Flex maxW={"1400px"} flexDir={'column'} p={4} m={"auto"} mt={"7.5rem"}>
                        <Heading>Blog EASYCAR</Heading>

                        <br />
                        <Flex flexDir={{ base: 'column', md: 'row' }} gap={3}>

                            <Box width={{ base: 'full', md: "100%" }} bg={"gray.50"}  >
                                {
                                    posts.length > 0 ?
                                        <Link to={`/blog/${posts[0].id}?${Math.random().toString(36)}`}>
                                            <Image alt={posts[0].title} maxHeight={"400px"} width={"full"} src={`${APIpath}/blog/image/${posts[0].id}`} objectFit='cover' />
                                            <Box minHeight={"150px"} p={6}>
                                                <Text noOfLines={1} fontSize={"xl"} as={"b"}>{posts[0].title}</Text>
                                                <Text noOfLines={3}> {posts[0].description}</Text>
                                            </Box>
                                        </Link>
                                        : "No se encuentran entradas"
                                }
                            </Box>

                            <Flex flexDir={"column"} gap={3} bg={"easycar1.500"} width={{ base: 'full', md: "400px" }} p={6} justifyContent={"center"}>
                                <Text fontSize='3xl' textAlign={"center"} color={"white"}>Conoce nuestro catálogo de vehiculos</Text>
                                <Image alt={'Vehiculos easycar'} height={"auto"} maxWidth={"300px"} src={vehiculo} />
                                <Button >Ver más</Button>
                            </Flex>
                        </Flex>

                        <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} spacing={"10"} p={"16px"} width={'full'}>
                            {
                                posts.map((post, index) => {
                                    if (index === 0) return ""
                                    else {
                                        return (
                                            <Box width={{ base: 'full', md: "100%" }} bg={"gray.50"} key={index} >
                                                <Link to={`/blog/${post.id}?${Math.random().toString(36)}`}>
                                                    <Image alt={post.title} height={"250px"} width={"full"} src={`${APIpath}/blog/image/${post.id}`} objectFit='cover' />
                                                    <Box height={"150px"} p={6}>
                                                        <Text noOfLines={1} fontSize={"xl"} as={"b"}>{post.title}</Text>
                                                        <Text noOfLines={3}> {post.description}</Text>
                                                    </Box>
                                                </Link>
                                            </Box>
                                        )
                                    }
                                })
                            }
                        </SimpleGrid>
                    </Flex>


                    <Box textAlign={"center"} w={"full"} mt={"7.5rem"} mb={"2rem"}>
                        <Heading>Lo que opinan nuestros clientes</Heading>
                    </Box>
                    <Box display={"flex"} flexDir={"column"} alignItems={"center"} justifyContent={"center"} w={"full"} textAlign={"center"} mb={"2rem"} >
                        <Flex w={"full"} flexDir={{ base: 'column', lg: 'row' }} justifyContent={"space-around"} alignItems={"center"} gap={"10px"}>
                            <iframe
                                className={"video"}
                                src="https://www.youtube.com/embed/C43o-mQwiNM"
                                title="Experiencia EASYCAR 1"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                                allowFullScreen></iframe>
                            <iframe
                                className={"video"}
                                src="https://www.youtube.com/embed/x88-Zx_nT9c"
                                title="Experiencia EASYCAR 2"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                                allowFullScreen></iframe>
                        </Flex>
                        <Link to={"/experiencia-easycar"} onClick={() => window.scrollTo(0, 0)}>
                            <Button colorScheme="easycar1" mt={"1rem"}>Ver más historias de clientes</Button>
                        </Link>
                    </Box>
                </Box>
            </Layout>
        )

    }

}

const mapStateToProps = (state) => ({
    posts: getPosts(state),
})

const mapDispatchToProps = { fetchActiveBlogs }

export default connect(mapStateToProps, mapDispatchToProps)(BlogLanding)
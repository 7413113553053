import Logo from '../multimedia/logo.png'
import { Link } from 'react-router-dom'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import NavItem from '../components/NavItem'
import NavItem2 from '../components/NavItem2'
import {
    Box,
    Flex,
    HStack,
    IconButton,
    Menu,
    Image,
    useColorModeValue,
    Stack,
    useDisclosure,
    Text,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Center
} from '@chakra-ui/react'
import HN from '../multimedia/icons/hn.png'

const Navbar = (props) => {

    const { isAuth, client, logout } = props
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Box bg={useColorModeValue('white', 'white.900')} px={4}>
            <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                <IconButton
                    size={'md'}
                    icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                    aria-label={'Open Menu'}
                    display={{ lg: 'none' }}
                    onClick={isOpen ? onClose : onOpen}
                />
                <Link to={"/"}><Image src={Logo} w={"auto"} h={"40px"} /></Link>
                <Flex alignItems={'center'} display={{ base: 'none', lg: 'flex' }}>
                    <HStack as={'nav'} display={{ base: 'none', lg: 'flex' }}>
                        <NavItem path={"/cars"}>Compra tu vehículo</NavItem>
                        <NavItem path={"/requisitos"}>Paga a cuotas</NavItem>
                        <NavItem path={"/puntos-pago"}>Puntos de pagos</NavItem>
                        <NavItem path={"/experiencia-easycar"}>Experiencia EASYCAR</NavItem>
                        <NavItem path={"/blog"}>Blog</NavItem>
                        <Menu>
                            <MenuButton>
                                <Image src={HN} w={"auto"} h={"40px"} />
                            </MenuButton>
                        </Menu>
                    </HStack>
                    {isAuth ?
                        <Menu>
                            <MenuButton py={2}>
                                <Text as='b' color="green.500">{`${client.cNombres} ${client.cNombres2 || ''} ${client.cApellidoPaterno} ${client.cApellidoMaterno || ''}`}</Text>
                            </MenuButton>
                            <MenuList alignItems={'center'}>
                                <Center><b>{`${client.cNombres} ${client.cNombres2 || ''} ${client.cApellidoPaterno} ${client.cApellidoMaterno || ''}`}</b></Center>
                                <MenuDivider />
                                <Link to={"/profile"}><MenuItem>Perfil</MenuItem></Link>
                                <Link to={"/profile/documents"}><MenuItem>Mis documentos</MenuItem></Link>
                                <Link to={"/status"}><MenuItem>Solicitudes</MenuItem></Link>
                                <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
                            </MenuList>
                        </Menu>
                        :
                        <NavItem path={"/login"} type={"green"}>Iniciar sesión</NavItem>
                    }
                </Flex>
            </Flex>
            {isOpen ? (
                <Box pb={4} display={{ lg: 'none' }}>
                    <Stack as={'nav'} spacing={4}>
                        <NavItem path={"/"}> Inicio</NavItem>
                        {isAuth ?
                            <div>
                                <NavItem path={"/profile"}>Perfil</NavItem>
                                <NavItem path={"/profile/documents"}>Mis documentos</NavItem>
                                <NavItem path={"/status"}>Solicitudes</NavItem>
                            </div>
                            :
                            <NavItem path={"/login"} color={"verde"}>Iniciar sesión</NavItem>
                        }
                        <NavItem path={"/cars"}>Compra tu vehículo</NavItem>
                        <NavItem path={"/requisitos"}>Paga a cuotas</NavItem>
                        <NavItem path={"/puntos-pago"}>Puntos de pagos</NavItem>
                        <NavItem path={"/experiencia-easycar"}>Experiencia EASYCAR</NavItem>
                        <NavItem path={"/blog"}>Blog</NavItem>
                        {isAuth &&
                            <NavItem2 onClick={logout}>Cerrar sesión</NavItem2>
                        }
                    </Stack>
                </Box>
            ) : null}
        </Box>
    )
}

export default Navbar
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure, Button
} from '@chakra-ui/react'


function ModalApp(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { children, title, content } = props
    return (
        <>
            <div onClick={onOpen}>
                {children}
            </div>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {content}
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='easycar1' mr={3} onClick={onClose}>Cerrar</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ModalApp